import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useDispatch } from "react-redux";
import { getBooking } from "../../store/booking/bookingActions";

export default function FileUpload({ current_booking, setCurrentBooking, url, file_type, type, tag_id }) {
    const { current_staff } = useSelector((state) => state.auth);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    console.log(current_booking);

    const onDrop = useCallback(async (acceptedFiles) => {
        setUploading(true);

        await Promise.all(
            acceptedFiles.map((file) => {
                uploadFile(file);
            })
        );
    }, []);

    // console.log(file_type);

    function uploadFile(file) {
        var form_data = new FormData();

        form_data.append("file", file);
        form_data.append("booking_id", current_booking?.id);
        form_data.append("user_id", current_booking?.user?.id);
        form_data.append("added_by", current_booking?.user?.id);
        form_data.append("file_type", file_type);

        if (current_staff) {
            form_data.append("logged_user_id", current_staff?.id);
            form_data.append("name", current_staff?.name);
            form_data.append("surname", current_staff?.surname);
            form_data.append("type", type);
            form_data.append("tag_id", tag_id);
        }

        axios.post(`${process.env.REACT_APP_API_URL}upload_file`, form_data).finally(() => {
            setUploading(false);

            if (url === undefined) {
                if (current_booking.url && current_booking.url !== null) {
                    url = current_booking.url;
                } else {
                    url = current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url;
                }
            }

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    if (setCurrentBooking) {
                        setCurrentBooking(response.data);
                    } else {
                        dispatch(getBooking(current_booking.id));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div className="bg-light_background mt-1 sm:mt-0 sm:col-span-2" {...getRootProps()}>
            <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-dark_background border-dashed rounded-md">
                <div className="space-y-1 text-center">
                    {!uploading ? (
                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    ) : (
                        <FontAwesomeIcon icon={regular("loader")} className="animate-spin h-12 w-12 text-gray-400" />
                    )}
                    <div className="flex text-sm text-gray-600">
                        <label htmlFor="file-upload" className="relative cursor-pointer bg-light_background rounded-md font-medium text-primary hover:text-primary_hover focus-within:outline-none">
                            {!uploading ? <span>Drop your files here</span> : <span>Files uploading</span>}
                            <input {...getInputProps()} />
                        </label>
                    </div>
                    {!uploading && <p className="text-xs text-gray-500">up to 10MB per file</p>}
                </div>
            </div>
        </div>
    );
}
