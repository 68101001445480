import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { getBooking } from "../../store/booking/bookingActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import NotificationToast from "../Helpers/NotificationToast";
// import ModalSnooze from "../Modals/ModalSnooze";
import { openModal } from "../../store/modal/modalReducer";
import { faBell, faBellSlash, faPlane } from "@fortawesome/pro-regular-svg-icons";
import { useLocation, useParams } from "react-router";

export default function BookingButtons({ setLoading, setDoc, db, current_booking, setUserIdSelected, historicBookings }) {
    const dispatch = useDispatch();
    let locationArea = useLocation();
    const { type } = useParams();
    var url_string = window.location.href;
    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");
    const { current_staff } = useSelector((state) => state.auth);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    var snooze_status = "Snooze";
    var snooze_icon = faBell;

    if (current_booking.snooze_until) {
        var now = new Date().getTime() / 1000;
        var end = new Date(current_booking.snooze_until).getTime() / 1000;
        var diff = end - now;

        if (diff > 0) {
            snooze_status = "Currently Snoozing";
            snooze_icon = faBellSlash;
        }
    }

    async function toggleBooking() {
        // functionality to also have booking remove itself from closed if new message comes in
        let booking_closed = type !== "collections" && type !== "renewals" ? current_booking.closed : current_booking.collection_closed;
        let booking_message = "";
        setLoading(true);
        if (booking_closed) {
            booking_closed = false;
            booking_message = "Opening";
        } else {
            booking_closed = true;
            booking_message = "Closing";
        }

        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}toggle/${current_booking.id}`,
            data: {
                closed: booking_closed,
                type: type,
                tag_id: tag_id,
                current_staff_id: current_staff.id,
            },
        })
            .then((response) => {
                toast(<NotificationToast title={"Booking " + booking_message + " Successful"} message={"The booking has been updated succesfully."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            })
            .finally(() => {
                setLoading(false);
                dispatch(getBooking());
            });
    }

    async function activate_booking() {
        setLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}activate_booking/${current_booking.id}`,
            data: {
                current_staff: current_staff,
            },
        })
            .then((response) => {
                toast(<NotificationToast title={"Booking Successful"} message={"The booking has been updated succesfully."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            })
            .finally(() => {
                setLoading(false);
                dispatch(getBooking());
            });
    }

    async function toggleDeclined() {
        setLoading(true);
        if (current_booking.status == "Declined") {
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}decline/${current_booking.id}`,
                data: {
                    declined: 0,
                    type: type,
                    tag_id: tag_id,
                    current_staff_id: current_staff.id,
                    user_id: current_booking?.user.id,
                },
            })
                .then((response) => {
                    toast(<NotificationToast title={"Decline removed"} message={"The booking has been updated succesfully."} />);
                })
                .catch((error) => {
                    toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(getBooking());
                });
        } else {
            dispatch(
                openModal({
                    modal_type: "ModalDecline",
                    modal_props: {
                        id: current_booking.id,
                        declined: current_booking.declined,
                        type: type,
                        tag_id: tag_id,
                        current_staff_id: current_staff.id,
                        user_id: current_booking?.user.id,
                    },
                })
            );
        }
        setLoading(false);
    }

    async function removeMention() {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}removemention/${current_booking.id}`,
            data: { type: type, tag_id: tag_id, current_staff_id: current_staff.id },
        })
            .then((response) => {
                toast(<NotificationToast title={"Mention removed"} message={"The booking has been updated succesfully."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            })
            .finally(() => {
                setLoading(false);
                dispatch(getBooking());
            });
    }

    async function toggleArchive() {
        setLoading(true);
        if (current_booking.archived) {
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}archive/${current_booking.id}`,
                data: {
                    archive: false,
                    type: type,
                    tag_id: tag_id,
                    current_staff_id: current_staff.id,
                },
            })
                .then((response) => {
                    toast(<NotificationToast title={"Unarchived"} message={"The booking has been updated succesfully."} />);
                })
                .catch((error) => {
                    toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(getBooking());
                });
        } else {
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}archive/${current_booking.id}`,
                data: {
                    archive: true,
                    type: type,
                    tag_id: tag_id,
                    current_staff_id: current_staff.id,
                },
            })
                .then((response) => {
                    toast(<NotificationToast title={"Archived Succesfully"} message={"The booking has been updated succesfully."} />);
                })
                .catch((error) => {
                    toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(getBooking());
                });
        }
        setLoading(false);
    }

    async function toggleArchiveAll() {
        setLoading(true);
        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}archive_all/${current_booking.id}`,
            data: {
                archive: true,
                type: type,
                tag_id: tag_id,
                current_staff_id: current_staff.id,
            },
        })
            .then((response) => {
                toast(<NotificationToast title={"Archived Succesfully"} message={"All bookings have been updated succesfully."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            })
            .finally(() => {
                setLoading(false);
                dispatch(getBooking());
            });
        setLoading(false);
    }

    async function stopBotFlow() {
        setLoading(true);
        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}stop_bot_flow/${current_booking.id}`,
            data: {
                current_staff_id: current_staff.id,
            },
        })
            .then((response) => {
                toast(<NotificationToast title={"Bot Flow Stopped Succesfully"} message={"All bookings have been updated succesfully."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            })
            .finally(() => {
                setLoading(false);
                dispatch(getBooking());
            });
        setLoading(false);
    }

    async function startCall() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}make_call`,
            data: { current_booking: current_booking, current_staff: current_staff },
        })
            .then((response) => {
                toast(<NotificationToast title={"Call Placed"} message={"The call has been placed."} />);
            })
            .catch((error) => {
                toast(<NotificationToast title="Something went wrong" message={error} type="error" />);
            });
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div className="flex">
                {/* {current_staff && current_staff.cell ? 
                    <button type="button" onClick={() => startCall()} className="justify-center w-full relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mr-2">
                        <FontAwesomeIcon icon={regular("phone")} className="h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                    </button>
                : "" } */}

                <Menu.Button className="justify-center w-full relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5">
                    <FontAwesomeIcon icon={regular("pen-to-square")} className="h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-light_background ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-20">
                    <div className="py-1">
                        {!current_staff.building_manager ? (
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className="group flex items-center px-4 py-2 text-sm w-full text-gray-600"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modal_type: "ModalAssign",
                                                    modal_props: {
                                                        id: current_booking.id,
                                                        assigned_to: current_booking.assigned_to,
                                                        currentLocation: locationArea.pathname.split("/").pop(),
                                                        type: type,
                                                    },
                                                })
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={regular("user-tag")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                        Assign
                                    </button>
                                )}
                            </Menu.Item>
                        ) : (
                            ""
                        )}

                        {current_booking?.assigned_to?.length > 0 && current_booking?.assigned_to.includes("" + current_staff.id) && (
                            <Menu.Item>
                                <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => removeMention()}>
                                    <FontAwesomeIcon icon={regular("user-minus")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                    Remove Myself
                                </button>
                            </Menu.Item>
                        )}

                        {!current_staff.building_manager ? (
                            <>
                                {type !== "collections" && type !== "renewals" ? (
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className="group flex items-center px-4 py-2 text-sm w-full text-gray-600"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "ModalSnooze",
                                                            modal_props: {
                                                                id: current_booking.id,
                                                                snooze_until: current_booking.snooze_until,
                                                                type: type,
                                                                tag_id: tag_id,
                                                                current_staff_id: current_staff.id,
                                                            },
                                                        })
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon icon={snooze_icon} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                {snooze_status}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ) : (
                                    ""
                                )}
                                <Menu.Item>
                                    {({ active }) => (
                                        <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => toggleBooking()}>
                                            {(type !== "collections" && type !== "renewals" && current_booking.closed) ||
                                            (type === "collections" && current_booking.collection_closed) ||
                                            (type === "renewals" && current_booking.renewals_closed) ? (
                                                <>
                                                    <FontAwesomeIcon icon={regular("user-check")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                    <span>Open</span>
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={regular("user-xmark")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                    <span>Close</span>
                                                </>
                                            )}
                                        </button>
                                    )}
                                </Menu.Item>

                                {type === "renewals" && current_booking.id !== current_booking?.user.active_booking ? (
                                    <>
                                        <Menu.Item>
                                            <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => activate_booking()}>
                                                <FontAwesomeIcon icon={regular("toggle-on")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                <span>Set Active Booking</span>
                                            </button>
                                        </Menu.Item>
                                    </>
                                ) : (
                                    ""
                                )}

                                {/* {current_booking.location === "residents" && (
                            <Menu.Item>
                                {({ active }) => (
                                    <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => toggleBooking()}>
                                        {current_booking.closed ? (
                                            <>
                                                <FontAwesomeIcon icon={regular("user-check")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                <span>Open</span>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={regular("user-xmark")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                <span>Close</span>
                                            </>
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                        )} */}

                                {type !== "collections" && type !== "renewals" ? (
                                    <>
                                        {/* {!process.env.REACT_APP_NO_DECLINE ||
                                            (process.env.REACT_APP_NO_DECLINE === "false" && ( */}
                                        <Menu.Item>
                                            <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => toggleDeclined()}>
                                                {current_booking.status === "Declined" ? (
                                                    <>
                                                        <FontAwesomeIcon icon={regular("square-check")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                        <span>Remove Declined</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon={regular("square-xmark")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                        <span>Decline</span>
                                                    </>
                                                )}
                                            </button>
                                        </Menu.Item>
                                        {/* ))} */}

                                        {process.env.REACT_APP_COLOUR !== "UC" ||
                                            (process.env.REACT_APP_COLOUR === "UC" && (current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za") && (
                                                <Menu.Item>
                                                    <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => toggleArchive()}>
                                                        {current_booking.archived ? (
                                                            <>
                                                                <FontAwesomeIcon icon={regular("lock-open")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                                <span>Unarchive</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon icon={regular("lock")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                                <span>Archive</span>
                                                            </>
                                                        )}
                                                    </button>
                                                </Menu.Item>
                                            ))}

                                        {/* {historicBookings && historicBookings.length <= 1 && ( */}

                                        {/* )} */}
                                    </>
                                ) : (
                                    ""
                                )}

                                {!current_booking.archived &&
                                    (process.env.REACT_APP_COLOUR !== "UC" ||
                                        (process.env.REACT_APP_COLOUR === "UC" && (current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za"))) && (
                                        <Menu.Item>
                                            <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => toggleArchiveAll()}>
                                                <>
                                                    <FontAwesomeIcon icon={regular("lock")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                    <span>Archive All</span>
                                                </>
                                            </button>
                                        </Menu.Item>
                                    )}

                                {current_booking.bot_flow_id > 0 && (
                                    <Menu.Item>
                                        <button className="group flex items-center px-4 py-2 text-sm w-full text-gray-600" onClick={() => stopBotFlow()}>
                                            <>
                                                <FontAwesomeIcon icon={regular("stop")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                                <span>Stop Bot Flow</span>
                                            </>
                                        </button>
                                    </Menu.Item>
                                )}

                                <Menu.Item>
                                    <button
                                        className="group flex items-center px-4 py-2 text-sm w-full text-gray-600"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modal_type: "ModalMergeChat",
                                                    modal_props: {
                                                        id: current_booking.id,
                                                        assigned_to: current_booking.assigned_to,
                                                        type: type,
                                                        tag_id: tag_id,
                                                    },
                                                })
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={regular("merge")} className="mr-3 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                        <span>Merge Chat</span>
                                    </button>
                                </Menu.Item>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
