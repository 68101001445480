import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Tab } from "@headlessui/react";
import { ScheduleMeeting } from "react-schedule-meeting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import LoadingComponent from "../Helpers/LoadingComponent";
import { format, addDays, getWeekOfMonth, getDay, addHours, roundToNearestMinutes, isEqual, isAfter, isBefore, startOfDay, endOfDay } from "date-fns";
import CustomButton from "../Helpers/CustomButton";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import PhoneInput from "react-phone-number-input";
import SettingsTextArea from "../Forms/SettingsTextarea";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function MoveInScheduler() {
    const [appointment, setAppointment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeBooking, setActiveBooking] = useState([]);
    const [timeChosen, setTimeChosen] = useState(false);
    const [timeScheduled, setTimeScheduled] = useState(false);
    const [alreadyBooked, setAlreadyBooked] = useState([]);
    const [cancelConfirmed, setCancelConfirmed] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [appointmentNotFound, setAppointmentNotFound] = useState(false);
    const [availableTimeslots, setAvailableTimeslots] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedToTime, setSelectedToTime] = useState("");
    const [activeAppointment, setActiveAppointment] = useState([]);
    const { type, id, action, appointment_id, url } = useParams();

    const reloadPage = () => {
        setAppointment([]);
        setLoading(true);
        setActiveBooking([]);
        setTimeChosen(false);
        setTimeScheduled(false);
        setAlreadyBooked([]);
        setCancelConfirmed(false);
        setCancelLoading(false);
        setAppointmentNotFound(false);
        setAvailableTimeslots([]);
        setSelectedDate("");
        setSelectedTime("");
        setSelectedToTime("");
        setActiveAppointment([]);
    };

    useEffect(async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
        }).then((response) => {
            setActiveBooking(response.data);
        });

        if (action !== "0" && appointment_id !== "0") {
            await axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}appointment_details/${appointment_id}`,
            }).then((response) => {
                if (response.data.length === 0) {
                    setAppointmentNotFound(true);
                } else {
                    setActiveAppointment(response.data);
                }
            });
        }

        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}current_appointment/${type}/${id}/${url}`,
        }).then((response) => {
            let resData = response.data[0];
            let fullData = response.data[1];
            setAppointment(resData);

            let days_arr = [];
            let days_full_arr = [];
            let i = 0;
            while (i < resData.days_amount) {
                if (
                    resData.available_type === 1 ||
                    (resData.available_type === 2 &&
                        (((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_from_date_1)) ||
                            isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_from_date_1))) &&
                            (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_to_date_1)) ||
                                isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jan_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.feb_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.mar_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.apr_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.may_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jun_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.jul_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.aug_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.sept_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.oct_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.nov_to_date_2)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_from_date_1)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_from_date_1))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_to_date_1)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_to_date_1)))) ||
                            ((isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_from_date_2)) ||
                                isAfter(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_from_date_2))) &&
                                (isEqual(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_to_date_2)) ||
                                    isBefore(new Date(format(addDays(new Date(), i), "yyyy-MM-dd")), new Date(resData?.custom?.dec_to_date_2))))))
                ) {
                    let varName = format(addDays(new Date(), i), "EEE").toLowerCase() + "_hours";
                    let startName = format(addDays(new Date(), i), "EEE").toLowerCase() + "_start";
                    let endName = format(addDays(new Date(), i), "EEE").toLowerCase() + "_end";

                    let allowDate = true;

                    let day_date = parseFloat(format(addDays(new Date(), i), "dd"));

                    if (resData.available_type === 1) {
                        if (resData.first_week === 1 && day_date > 0 && day_date < 8) {
                            allowDate = true;
                        } else if (resData.second_week === 1 && day_date > 7 && day_date < 15) {
                            allowDate = true;
                        } else if (resData.third_week === 1 && day_date > 14 && day_date < 22) {
                            allowDate = true;
                        } else if (resData.fourth_week === 1 && day_date > 21) {
                            allowDate = true;
                        } else {
                            allowDate = false;
                        }
                    }

                    async function processBlockedDays(array) {
                        for (const blocked of array) {
                            if (format(addDays(new Date(), i), "yyyy-MM-dd") === blocked.blocked_date) {
                                allowDate = false;
                            }
                        }
                    }
                    processBlockedDays(resData.blocked);

                    if (resData[varName] === 1 && allowDate === true) {
                        days_arr.push({ id: i, start: resData[startName], end: resData[endName] });
                    }
                }

                i++;
            }

            let debug = true;

            async function processFullDays(array) {
                let managerCount = resData.managers.split(",").length;
                debug && console.log(managerCount);
                let current_session_date = 0;
                let current_session_time = 0;
                let session_booked = 0;
                for (const full of array) {
                    if (full.booked_date != format(new Date(), "yyyy-MM-dd")) {
                        debug && console.log(resData);

                        //TODO: test how on property calendar works in relation to being off

                        if (resData.on_property_calendar === 1) {
                            days_full_arr.push({
                                startTime: new Date(full.booked_date + " " + full.booked_time),
                                endTime: new Date(full.booked_date + " " + full.time_to),
                            });
                        } else {
                            // console.log(session_booked, managerCount);
                            if (full.booked_date !== current_session_date || full.booked_time !== current_session_time) {
                                session_booked = 0;
                            }

                            if (current_session_date === 0 && current_session_time === 0) {
                                current_session_date = full.booked_date;
                                current_session_time = full.booked_time;
                            }

                            if (current_session_date !== 0 && current_session_time !== 0 && full.booked_date === current_session_date && full.booked_time === current_session_time) {
                                session_booked = session_booked + 1;
                                if (session_booked >= managerCount) {
                                    days_full_arr.push({
                                        startTime: new Date(full.booked_date + " " + full.booked_time),
                                        endTime: new Date(full.booked_date + " " + full.time_to),
                                    });
                                }
                            }

                            current_session_date = full.booked_date;
                            current_session_time = full.booked_time;
                        }
                    }
                }
            }

            //Booking Block out first 2 hours of current time
            // days_full_arr.push({
            //     startTime: new Date(format(new Date(), "yyyy-MM-dd")+" "+format(new Date(), "HH:mm")),
            //     endTime: new Date(format(new Date(), "yyyy-MM-dd")+" "+format(roundToNearestMinutes(addHours(new Date(), 2), {nearestTo: 30}), "HH:mm"))
            // })

            //Booking block out current day to avoid same day booking
            days_full_arr.push({
                startTime: startOfDay(new Date()),
                endTime: endOfDay(new Date()),
            });

            processFullDays(fullData);

            console.log(days_full_arr);

            let avail_days = [];

            async function processDays(array) {
                for (const day of array) {
                    const [startHours, startMinutes] = day.start.split(":");
                    const [endHours, endMinutes] = day.end.split(":");

                    let interuption_found = false;
                    let first_start = new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(startHours, startMinutes, 0, 0));
                    let default_start = new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(startHours, startMinutes, 0, 0));

                    async function processArray(array) {
                        for (const full of array) {
                            if (format(new Date(full.startTime), "yy-MM-dd") === format(new Date(new Date(new Date().setDate(new Date().getDate() + day.id))), "yy-MM-dd")) {
                                interuption_found = true;
                                if (first_start.getTime() === default_start.getTime()) {
                                    avail_days.push({
                                        id: day.id,
                                        startTime: new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(startHours, startMinutes, 0, 0)),
                                        endTime: full.startTime,
                                    });
                                    first_start = full.endTime;
                                } else {
                                    avail_days.push({
                                        id: day.id,
                                        startTime: first_start,
                                        endTime: full.startTime,
                                    });
                                    first_start = full.endTime;
                                }
                            }
                        }
                    }

                    processArray(days_full_arr);

                    if (first_start.getTime() !== default_start.getTime()) {
                        avail_days.push({
                            id: day.id,
                            startTime: first_start,
                            endTime: new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(endHours, endMinutes, 0, 0)),
                        });
                    }

                    if (interuption_found === false) {
                        avail_days.push({
                            id: day.id,
                            startTime: new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(startHours, startMinutes, 0, 0)),
                            endTime: new Date(new Date(new Date().setDate(new Date().getDate() + day.id)).setHours(endHours, endMinutes, 0, 0)),
                        });
                    }
                }
            }

            processDays(days_arr);
            setAvailableTimeslots(avail_days);
            setLoading(false);
        });
    }, [loading]);

    const handleTimeslotClicked = (startTimeEventEmit) => {
        startTimeEventEmit.resetDate();
        startTimeEventEmit.resetSelectedTimeState();
        setTimeChosen(true);
        setSelectedDate(format(startTimeEventEmit.startTime, "yyyy-MM-dd"));
        setSelectedTime(format(startTimeEventEmit.startTime, "HH:mm:ss"));

        let totalDuration = 0;

        if (appointment.duration == 1) {
            totalDuration = 15;
        } else if (appointment.duration == 2) {
            totalDuration = 30;
        } else if (appointment.duration == 3) {
            totalDuration = 45;
        } else if (appointment.duration == 4) {
            totalDuration = 60;
        } else if (appointment.duration == 5) {
            totalDuration = 75;
        } else if (appointment.duration == 6) {
            totalDuration = 90;
        } else if (appointment.duration == 7) {
            totalDuration = 105;
        } else if (appointment.duration == 8) {
            totalDuration = 120;
        }

        let endTime = new Date(new Date(new Date(startTimeEventEmit.startTime)).setMinutes(new Date(startTimeEventEmit.startTime).getMinutes() + totalDuration));
        setSelectedToTime(format(endTime, "HH:mm:ss"));
    };

    async function cancelBooking() {
        setCancelLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}cancel_appointment`,
            data: { main_appointment_id: id, appointment_id: appointment_id, url: url },
        }).then((response) => {
            setCancelConfirmed(true);
        });
    }

    if (loading === true) {
        return <LoadingComponent />;
    }

    return (
        <>
            {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" ? (
                <>
                    <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="lg:flex">
                                {process.env.REACT_APP_COLOUR === "QL" ? (
                                    <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                                ) : (
                                    <img className="h-24" src="/images/q-properties.png" alt="Quorum Properties" />
                                )}
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                                    <h1 className="text-3xl text-white">
                                        Welcome {url && "back"} to {process.env.REACT_APP_COLOUR === "QL" ? "QLiving" : "Quorum Properties"}{" "}
                                        {url && activeBooking && activeBooking.user && activeBooking?.user.name + " " + activeBooking?.user.surname}
                                    </h1>
                                </div>
                            </div>
                        </header>
                    </div>
                </>
            ) : (
                <>
                    <div className="pb-32 elative bg-dark_background">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="md:flex items-center">
                                <img className="h-24 mx-auto" src="/images/logo_uc.svg" alt="Urban Circle" />
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center text-white text-4xl uppercase font-bold">
                                    <h1>APPOINTMENTS</h1>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        href="https://api.whatsapp.com/send?phone=270872325511"
                                        className="h-12 rounded-full text-white px-5 flex justify-between items-center font-bold"
                                        style={{ background: "#62D43F" }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25.364" height="25.364" viewBox="0 0 25.364 25.364" className="mr-5">
                                            <path
                                                id="Icon_simple-whatsapp"
                                                data-name="Icon simple-whatsapp"
                                                d="M18.492,15.2c-.318-.159-1.867-.916-2.156-1.021s-.5-.159-.711.159-.815,1.019-1,1.228-.369.222-.683.079a8.577,8.577,0,0,1-2.54-1.569,9.588,9.588,0,0,1-1.754-2.188c-.184-.317-.02-.491.137-.65.144-.143.318-.365.477-.553a2.679,2.679,0,0,0,.314-.524.581.581,0,0,0-.026-.554c-.079-.159-.71-1.712-.974-2.331s-.515-.539-.71-.539c-.182-.016-.392-.016-.6-.016a1.191,1.191,0,0,0-.842.379,3.528,3.528,0,0,0-1.1,2.616,6.184,6.184,0,0,0,1.288,3.25A14.092,14.092,0,0,0,13,17.705c.755.317,1.342.507,1.8.665a4.372,4.372,0,0,0,1.987.128,3.252,3.252,0,0,0,2.131-1.507,2.616,2.616,0,0,0,.19-1.506c-.078-.143-.285-.222-.6-.365m-5.756,7.866H12.73a10.572,10.572,0,0,1-5.342-1.458l-.38-.226-3.963,1.03L4.106,18.48l-.253-.4A10.482,10.482,0,0,1,20.178,5.153a10.344,10.344,0,0,1,3.074,7.387,10.5,10.5,0,0,1-10.5,10.447M21.686,3.645A12.832,12.832,0,0,0,12.73,0,12.565,12.565,0,0,0,1.792,18.852L0,25.364l6.7-1.746a12.748,12.748,0,0,0,6.035,1.529h.006A12.619,12.619,0,0,0,25.364,12.575,12.465,12.465,0,0,0,21.67,3.686"
                                                fill="#fff"
                                            />
                                        </svg>
                                        <span className="mr-3">NEED HELP?</span>
                                    </a>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className="-mt-32">
                        <div>
                            <div className="bg-white h-60 rounded-tl-3xl white_block_left">
                                <div className="h-20 flex">
                                    <div className="flex-1 grow bg-primary z-50 flex items-center px-11 shadow-md" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                        <div className="text-white sm:text-xs md:text-2xl font-bold uppercase">CHOOSE YOUR APPOINTMENT TIME</div>
                                    </div>
                                    <div className="w-1/5 colour-uc2 z-40" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc3 z-30" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc4 z-20" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <main className="-mt-32">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div className="w-full">
                            <div className="xl:grid xl:grid-cols-12">
                                <Tab.Group selectedIndex="0">
                                    <Tab.List className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 rounded-3xl lg:rounded-l-3xl w-full xl:w-72 border-0 xl:rounded-r-none">
                                        <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-3xl xl:rounded-tr-none justify-center bg-dark_background">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize"></h1>
                                        </div>
                                        <Tab
                                            key="0"
                                            className="group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none border-l-primary bg-gray-100 text-primary"
                                        >
                                            <span className="truncate whitespace-normal text-left">
                                                {appointmentNotFound === false ? (
                                                    <>
                                                        <p className="font-semibold ">{(appointment?.property.name).split(" - ")[0]}</p>
                                                        <p className="text-xl">
                                                            <b>{appointment.name}</b>
                                                        </p>
                                                        <br />
                                                        <p className="font-semibold ">
                                                            <FontAwesomeIcon icon={regular("clock")} />{" "}
                                                            {appointment.duration === 1
                                                                ? "15 min"
                                                                : appointment.duration === 2
                                                                ? "30 min"
                                                                : appointment.duration === 3
                                                                ? "45 min"
                                                                : appointment.duration === 4
                                                                ? "1 hour"
                                                                : appointment.duration === 5
                                                                ? " 1 hour 15 min"
                                                                : appointment.duration === 6
                                                                ? "1 hour 30 min"
                                                                : appointment.duration === 7
                                                                ? "1 hour 45 min"
                                                                : appointment.duration === 8
                                                                ? "2 hours"
                                                                : ""}
                                                        </p>
                                                        <br />
                                                        <p className="font-semibold ">
                                                            <FontAwesomeIcon icon={regular("location-dot")} />
                                                            {appointment.show_unit === 1 ? (activeBooking?.unit?.name ? " Unit " + activeBooking?.unit?.name : " Your Unit") : ""}
                                                            {appointment.show_unit === 1 ? (activeBooking?.bed?.name ? "  " + activeBooking?.bed?.name : " Your Unit") + " @ " : ""}
                                                            {(appointment?.property.name).split(" - ")[0]}: {appointment?.property.address}
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <p>{appointment.description}</p>
                                                    </>
                                                ) : (
                                                    ""
                                                )}

                                                {action !== "0" && (action === "1" || action === "2") && cancelConfirmed === false && appointmentNotFound === false ? (
                                                    <>
                                                        <br />
                                                        <p className="font-semibold">Current booking scheduled for:</p>
                                                        <p>{format(new Date(activeAppointment.booked_date), "ccc, dd MMMM yyyy")}</p>
                                                        <p>
                                                            {format(new Date(activeAppointment.booked_date + " " + activeAppointment.booked_time), "HH:mm")} -{" "}
                                                            {format(new Date(activeAppointment.booked_date + " " + activeAppointment.time_to), "HH:mm")}
                                                        </p>
                                                        {selectedDate !== "" ? (
                                                            <>
                                                                <br />
                                                                <p className="font-semibold">Updating to:</p>
                                                                <p>{format(new Date(selectedDate), "ccc, dd MMMM yyyy")}</p>
                                                                <p>
                                                                    {format(new Date(selectedDate + " " + selectedTime), "HH:mm")} - {format(new Date(selectedDate + " " + selectedToTime), "HH:mm")}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : appointmentNotFound === true ? (
                                                    <>
                                                        <p className="font-semibold">This appointment could not be found</p>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                        </Tab>
                                    </Tab.List>
                                    <Tab.Panels className="xl:ml-20 my-6 xl:my-0 rounded-3xl xl:rounded-l-none xl:rounded-r-3xl bg-gray-100 xl:col-span-9">
                                        <Tab.Panel className="xl:col-span-9 pb-12">
                                            <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-none xl:rounded-tr-3xl justify-center bg-dark_background">
                                                <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Select a Date & Time</h1>
                                            </div>
                                            {appointmentNotFound === true ? (
                                                <>
                                                    <p className="font-semibold p-5">This appointment could not be found!</p>
                                                </>
                                            ) : (
                                                <>
                                                    {action === "2" ? (
                                                        <>
                                                            {cancelConfirmed === false ? (
                                                                <div className="p-5">
                                                                    <h1 className="font-semibold text-lg">Are you sure you want to cancel your booking?</h1>
                                                                    <br />
                                                                    <CustomButton text="Cancel Booking" loading={cancelLoading} onClick={() => cancelBooking()} />
                                                                </div>
                                                            ) : (
                                                                <p className="text-center mt-2 text-xl">Your appointment has been cancelled.</p>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {timeChosen === false && timeScheduled === false ? (
                                                                <>
                                                                    <ScheduleMeeting
                                                                        eventStartTimeSpreadInMinutes={appointment.minute_break}
                                                                        borderRadius={10}
                                                                        primaryColor={process.env.REACT_APP_COLOUR === "UC" ? "#EE355C" : process.env.REACT_APP_COLOUR === "QL" ? "#9F7D38" : "#423b3b"}
                                                                        eventDurationInMinutes={
                                                                            appointment.duration === 1
                                                                                ? 15
                                                                                : appointment.duration === 2
                                                                                ? 30
                                                                                : appointment.duration === 3
                                                                                ? 45
                                                                                : appointment.duration === 4
                                                                                ? 60
                                                                                : appointment.duration === 5
                                                                                ? 75
                                                                                : appointment.duration === 6
                                                                                ? 90
                                                                                : appointment.duration === 7
                                                                                ? 105
                                                                                : appointment.duration === 8
                                                                                ? 120
                                                                                : 30
                                                                        }
                                                                        availableTimeslots={availableTimeslots}
                                                                        onStartTimeSelect={handleTimeslotClicked}
                                                                        startTimeListStyle="scroll-list"
                                                                    />
                                                                </>
                                                            ) : timeChosen === true && timeScheduled === false ? (
                                                                <>
                                                                    <div className="mt-2 flex justify-between">
                                                                        <div>
                                                                            <CustomButton icon={regular("arrow-left")} onClick={() => setTimeChosen(false)} />
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <p className="text-xl text-center">Please confirm your details</p>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <Formik
                                                                            initialValues={{
                                                                                name:
                                                                                    action === "0" && activeBooking?.user.name
                                                                                        ? activeBooking.user.name
                                                                                        : activeAppointment.name
                                                                                        ? activeAppointment.name
                                                                                        : "",
                                                                                email:
                                                                                    action === "0" && activeBooking?.user.email
                                                                                        ? activeBooking.user.email
                                                                                        : activeAppointment.email
                                                                                        ? activeAppointment.email
                                                                                        : "",
                                                                                cell:
                                                                                    action === "0" && activeBooking?.user.cell
                                                                                        ? "+" + activeBooking.user.cell
                                                                                        : activeAppointment.cell
                                                                                        ? "+" + activeAppointment.cell
                                                                                        : "",
                                                                                description: action !== "0" ? (activeAppointment.description ? activeAppointment.description : "") : "",
                                                                            }}
                                                                            enableReinitialize
                                                                            validate={(values) => {
                                                                                const errors = {};
                                                                                if (!values.name) {
                                                                                    errors.name = "Required";
                                                                                }
                                                                                if (!values.email) {
                                                                                    errors.email = "Required";
                                                                                }

                                                                                return errors;
                                                                            }}
                                                                            onSubmit={async (values, { setSubmitting, setErrors }) => {
                                                                                values.cell = values.cell && values.cell.replace("+", "");
                                                                                values.property_id = appointment.property_id;
                                                                                values.unit_id = activeBooking.unit_id;
                                                                                values.bed_id = activeBooking.bed_id;
                                                                                values.booking_ref = activeBooking.booking_reference;
                                                                                values.appointment_url = type;
                                                                                values.booked_date = selectedDate;
                                                                                values.booked_time = selectedTime;
                                                                                values.time_to = selectedToTime;
                                                                                values.appointment_id = id;
                                                                                values.booking_id = activeBooking.id;
                                                                                values.this_appointment_id = appointment_id;

                                                                                var link = "";

                                                                                if (action == 0) {
                                                                                    var link = "create_appointment";
                                                                                } else if (action == 1) {
                                                                                    var link = "change_appointment";
                                                                                } else if (action == 2) {
                                                                                    var link = "cancel_appointment";
                                                                                }

                                                                                await axios({
                                                                                    method: "post",
                                                                                    url: process.env.REACT_APP_API_URL + "" + link,
                                                                                    data: values,
                                                                                })
                                                                                    .then((response) => {
                                                                                        setTimeScheduled(true);
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        if (error.response) {
                                                                                            setAlreadyBooked([
                                                                                                error.response.data.error,
                                                                                                error.response.data.appointment_edit_link.replace(/\s/g, "%20"),
                                                                                                error.response.data.appointment_cancel_link.replace(/\s/g, "%20"),
                                                                                                error.response.data.new_appointment_link.replace(/\s/g, "%20"),
                                                                                            ]);
                                                                                            setTimeScheduled(true);
                                                                                        } else if (error.request) {
                                                                                            console.log(error.request);
                                                                                            alert("An error occurred while scheduling the appointment. Please try again.");
                                                                                        } else {
                                                                                            console.log("Error", error.message);
                                                                                            alert("An error occurred while scheduling the appointment. Please try again.");
                                                                                        }
                                                                                    });
                                                                            }}
                                                                        >
                                                                            {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                                                                                <Form onSubmit={handleSubmit} className="mx-5 sm:mx-20">
                                                                                    <br />
                                                                                    <Field name="name" as={SettingsTextInput} type="text" label="Name" />
                                                                                    <p className="text-red-400 text-sm font-light">{errors.name}</p>
                                                                                    <br />
                                                                                    <Field name="email" as={SettingsTextInput} type="email" label="Email Address" />
                                                                                    <p className="text-red-400 text-sm font-light">{errors.email}</p>
                                                                                    <br />
                                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                                        <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                                            <span>Cell</span>
                                                                                            {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                                                        </label>
                                                                                        <div className="mt-1">
                                                                                            <PhoneInput
                                                                                                defaultCountry="ZA"
                                                                                                international
                                                                                                countryCallingCodeEditable={false}
                                                                                                onChange={(val) => {
                                                                                                    setFieldValue("cell", val);
                                                                                                }}
                                                                                                name="cell"
                                                                                                value={values.cell}
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    <SettingsTextArea
                                                                                        name="description"
                                                                                        placeholder="Please share anything that will help prepare for our meeting."
                                                                                        errors={errors.description}
                                                                                        emojis={false}
                                                                                        setFieldValue={setFieldValue}
                                                                                        value={values.description}
                                                                                        wysiwyg={false}
                                                                                    />
                                                                                    <br />
                                                                                    <SubmitButton
                                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                                        isSubmitting={isSubmitting}
                                                                                        type="submit"
                                                                                        name="submit"
                                                                                        text="Request Appointment"
                                                                                    />
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {alreadyBooked && alreadyBooked.length > 0 && (
                                                                        <div className="flex flex-col gap-2">
                                                                            <p className="text-center mt-2 text-xl">{alreadyBooked[0]}</p>
                                                                            <div className="flex gap-2 justify-center">
                                                                                {alreadyBooked[1] !== "" && (
                                                                                    <Link to={alreadyBooked[1]} onClick={() => reloadPage()}>
                                                                                        <CustomButton text="Modify Booking" styling="w-full mt-2" icon={regular("circle-user")} />
                                                                                    </Link>
                                                                                )}
                                                                                {alreadyBooked[2] !== "" && (
                                                                                    <Link to={alreadyBooked[2]} onClick={() => reloadPage()}>
                                                                                        <CustomButton text="Cancel Booking" styling="w-full mt-2" icon={regular("circle-user")} />
                                                                                    </Link>
                                                                                )}
                                                                                {alreadyBooked[3] !== "" && (
                                                                                    <Link to={alreadyBooked[3]} onClick={() => reloadPage()}>
                                                                                        <CustomButton text="Book a different slot" styling="w-full mt-2" icon={regular("circle-user")} />
                                                                                    </Link>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {action === "0" && alreadyBooked.length == 0 ? (
                                                                        <p className="text-center mt-2 text-xl">Thank you for requesting an appointment!</p>
                                                                    ) : action === "1" ? (
                                                                        <p className="text-center mt-2 text-xl">Thank you for requesting an update to your appointment!</p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
