import React, { useEffect, useState, useRef, Fragment, useCallback } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import AppLoader from "../../components/Helpers/AppLoader";
import { Dialog, Transition } from "@headlessui/react";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import QuickFormSelect from "../../components/Forms/QuickFormSelect";
import BookingTextArea from "../../components/Booking/BookingTextArea";
import SubmitButton from "../../components/Forms/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleRight, faPenCircle, faPlusCircle, faSave, faTrashCircle, faClone } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import SettingsTextArea from "../../components/Forms/SettingsTextarea";
import { Link } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function EditUser() {
    const { staff } = useSelector((state) => state.auth);
    const cancelButtonRef = useRef(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bot_flows, setBotFlows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        id: 0,
        parent_bot_flow_id: 0,
        name: "",
    });
    const [locations, setLocations] = useState([]);
    const { tags } = useSelector((state) => state.tags);
    const [uploading, setUploading] = useState(false);
    const [years, setYears] = useState([]);

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
        })
            .then((response) => {
                setBotFlows(response.data[0]);
                setYears(response.data[1]);
                setLoading(false);
            })
            .catch((error) => {
                alert("Failed to get bot flows");
                setLoading(false);
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}folders`,
        }).then((response) => {
            let allFolders = response.data.folders;
            let locationList = [];

            Object.keys(allFolders).forEach((key) => {
                if (allFolders[key].name !== "All" && allFolders[key].name !== "Declined" && allFolders[key].name !== "Cancelled" && allFolders[key].name !== "Archived") {
                    if (allFolders[key].name == "All Residents") {
                        allFolders[key].name = "Residents";
                    }

                    locationList.push({
                        value: allFolders[key].name,
                        label: allFolders[key].name,
                    });
                }
            });

            setLocations(locationList);
        });
    }, []);

    if (loading === true) {
        return <AppLoader />;
    }

    const open_popup = (e, id, child_id, task) => {
        e.preventDefault();

        if (child_id > 0 && task === "edit") {
            setInitialValues(bot_flows.filter((flow) => flow.id === id)[0].children.filter((child) => child.id === child_id)[0]);
        } else if (child_id == 0 && task === "edit") {
            setInitialValues(bot_flows.filter((flow) => flow.id === id)[0]);
        } else if (id > 0 && task === "add") {
            setInitialValues({
                id: 0,
                parent_bot_flow_id: id,
                name: "",
            });
        } else {
            setInitialValues({
                id: 0,
                parent_bot_flow_id: 0,
                name: "",
            });
        }

        setOpenPopup(1);
    };

    const handleDelete = (e, id) => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to delete this flow?")) {
            axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}delete_bot_flow`,
                data: {
                    id: id,
                },
            })
                .then((resp) => {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                    })
                        .then((response) => {
                            setBotFlows(response.data[0]);
                            setYears(response.data[1]);
                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("Failed to get bot flows");
                            setLoading(false);
                        });
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
        }
    };

    const edit_rules = (e, bot_flow_id, child_id, rule_id, action) => {
        e.preventDefault();

        if (action == "edit") {
            if (child_id > 0) {
                setInitialValues(
                    bot_flows
                        .find((flow) => flow.id === bot_flow_id)
                        ?.children?.find((child) => child.id == child_id)
                        ?.bot_flow_rules.find((rule) => rule.id === rule_id)
                );
            } else {
                setInitialValues(bot_flows.find((flow) => flow.id === bot_flow_id)?.bot_flow_rules.filter((rule) => rule.id === rule_id)[0]);
            }
            setOpenPopup(2);
        }

        if (action == "add") {
            setInitialValues({
                id: 0,
                bot_flow_id: child_id > 0 ? child_id : bot_flow_id,
                incoming_message: "",
                do_send_message: 0,
                message_to_send: "",
                do_move_chat: 0,
                move_to_location: "",
                end_bot_flow: 0,
                bot_type: "Whatsapp",
                action: "All",
            });

            setOpenPopup(2);
        }

        if (action == "delete") {
            if (window.confirm("Are you sure you want to delete this rule?")) {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}delete_bot_flow_rule`,
                    data: {
                        id: rule_id,
                    },
                })
                    .then((resp) => {
                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                        })
                            .then((response) => {
                                setBotFlows(response.data[0]);
                                setYears(response.data[1]);
                                setLoading(false);
                            })
                            .catch((error) => {
                                alert("Failed to get bot flows");
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        }
    };

    const edit_options = (e, bot_flow_id, child_id, rule_id, action) => {
        e.preventDefault();

        if (action == "edit") {
            if (child_id > 0) {
                setInitialValues(
                    bot_flows
                        .find((flow) => flow.id === bot_flow_id)
                        ?.children?.find((child) => child.id == child_id)
                        ?.bot_flow_options.find((rule) => rule.id === rule_id)
                );
            } else {
                setInitialValues(bot_flows.filter((flow) => flow.id === bot_flow_id)[0].bot_flow_options.filter((rule) => rule.id === rule_id)[0]);
            }

            setOpenPopup(3);
        }

        if (action == "add") {
            setInitialValues({
                id: 0,
                bot_flow_id: child_id > 0 ? child_id : bot_flow_id,
                option_name: "",
                sort_order: 0,
            });
            setOpenPopup(3);
        }

        if (action == "delete") {
            if (window.confirm("Are you sure you want to delete this rule?")) {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}delete_bot_flow_option`,
                    data: {
                        id: rule_id,
                    },
                })
                    .then((resp) => {
                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                        })
                            .then((response) => {
                                setBotFlows(response.data[0]);
                                setYears(response.data[1]);
                                setLoading(false);
                            })
                            .catch((error) => {
                                alert("Failed to get bot flows");
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        }
    };

    const duplicateBotFlow = (id) => {
        if (window.confirm("Are you sure you want to duplicate this flow?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}clone_bot_flow`,
                data: { id: id },
            })
                .then((response) => {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                    })
                        .then((response) => {
                            setBotFlows(response.data[0]);
                            setYears(response.data[1]);
                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("Failed to get bot flows");
                            setLoading(false);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
            <div className="flex justify-between mb-8 items-center">
                <h1 className="text-lg font-bold">Bot Flows</h1>

                <div className="flex space-x-2">
                    <button className="bg-primary text-white px-4 py-2 rounded-full" onClick={(e) => open_popup(e, 0, 0, "add")}>
                        Add a Flow
                    </button>

                    <Link to="/admin_lookups/dashboard" className="bg-primary text-white px-4 py-2 rounded-full">
                        Lookups
                    </Link>
                </div>
            </div>

            {bot_flows?.map((bot_flow) => (
                <div>
                    <div className="mb-8 bg-gray-200 px-4 py-2">
                        <div className="flex justify-between">
                            <div title={bot_flow.id}>
                                <strong>Name:</strong> {bot_flow.name} ({bot_flow.status}) - {bot_flow.children.length} {bot_flow.children.length == 1 ? "Item" : "Items"}
                            </div>
                            <div className="space-x-2 flex items-center">
                                <div>{bot_flow.sort_order}</div>
                                <button onClick={() => setIsOpen(isOpen === bot_flow.id ? false : bot_flow.id)}>
                                    {isOpen === bot_flow.id ? (
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faChevronCircleDown} />
                                    ) : (
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faChevronCircleRight} />
                                    )}
                                </button>

                                {isOpen === bot_flow.id && (
                                    <button onClick={(e) => open_popup(e, bot_flow.id, 0, "edit")}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                    </button>
                                )}

                                {isOpen === bot_flow.id && (
                                    <button onClick={(e) => duplicateBotFlow(bot_flow.id)}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faClone} />
                                    </button>
                                )}

                                {isOpen === bot_flow.id && bot_flow.children.length == 0 && (
                                    <button onClick={(e) => handleDelete(e, bot_flow.id)}>
                                        <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                    </button>
                                )}
                            </div>
                        </div>

                        {isOpen === bot_flow.id && (
                            <div>
                                <div>
                                    <strong>Bot Type:</strong> {bot_flow.bot_type}
                                    <br />
                                    <strong>Action:</strong> {bot_flow.action}
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div>
                                        <strong>Match Message:</strong> {bot_flow.match_message === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">{bot_flow.incoming_message}</div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Match Location:</strong> {bot_flow.match_location === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.current_location} {bot_flow.current_tag_id > 0 && " - (Tag = " + tags.filter((tag) => tag.id === bot_flow.current_tag_id)[0]?.name + ")"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Move Chat:</strong> {bot_flow.do_move_chat === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.move_to_location}
                                        {bot_flow.move_to_tag > 0 && " - (Tag = " + tags.filter((tag) => tag.id === bot_flow.move_to_tag)[0]?.name + ")"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Do Action:</strong> {bot_flow.do_action === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.action_to_do == 1 && (
                                            <>
                                                <strong>Action to do:</strong> Create Booking - {bot_flow.booking_year && years.find((y) => y.value == bot_flow.booking_year)?.label}
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Close:</strong> {bot_flow.do_close === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Archive:</strong> {bot_flow.do_archive === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>End Flow:</strong> {bot_flow.end_bot_flow === 1 ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="grid grid-cols-4">
                                    <div>
                                        <strong>Message Type:</strong> {bot_flow.message_type == 0 ? "Normal Message" : bot_flow.message_type == 1 ? "List" : "Buttons"}
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div>
                                        <strong>Send Message:</strong> {bot_flow.do_send_message === 1 ? "Yes" : "No"}
                                    </div>
                                    <div className="col-span-3">
                                        {bot_flow.bot_type == "Email" && (
                                            <div>
                                                <strong>Email Subject:</strong> {bot_flow.email_subject}
                                            </div>
                                        )}
                                        <div>
                                            <strong>Robot Colour:</strong> {bot_flow.robot_colour === 0 ? "Red" : "Green"}
                                        </div>
                                        <div>
                                            <strong>Message:</strong> <pre className="text-base">{bot_flow.do_send_message === 1 && bot_flow.message_to_send}</pre>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div className="flex justify-start items-start">
                                        <div className="mr-4">
                                            <strong>Rules:</strong> {bot_flow.bot_flow_rules?.length > 0 ? "Yes" : "No"}
                                        </div>

                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, 0, "add")}>
                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                        </button>
                                    </div>

                                    {bot_flow.bot_flow_rules?.length > 0 && (
                                        <div className="col-span-3">
                                            {bot_flow.bot_flow_rules.map((rule) => (
                                                <div className="mb-4">
                                                    <div className="space-x-2">
                                                        <strong>Incoming Message:</strong> {rule.incoming_message} ({rule.status})
                                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_rules(e, bot_flow.id, 0, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>Funding Type:</strong> {rule.do_match_funding_type > 0 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-3">{rule.do_match_funding_type === 1 && rule.current_funding_type}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>Send Message:</strong> {rule.do_send_message === 1 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-3">{rule.do_send_message === 1 && rule.message_to_send}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>Move Location:</strong> {rule.do_move_chat === 1 ? "Yes" : "No"}
                                                        </div>
                                                        <div className="col-span-3">{rule.do_move_chat === 1 && rule.move_to_location}</div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div>
                                                            <strong>End Flow:</strong> {rule.end_bot_flow === 1 ? "Yes" : "No"}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="mt-4 grid grid-cols-4">
                                    <div className="flex justify-start items-start">
                                        <div className="mr-4">
                                            <strong>Button Options:</strong> {bot_flow.bot_flow_options?.length > 0 ? "Yes" : "No"}
                                        </div>
                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, 0, "add")}>
                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                        </button>
                                    </div>

                                    {bot_flow.bot_flow_options?.length > 0 && (
                                        <div className="col-span-3">
                                            {bot_flow.bot_flow_options
                                                ?.sort((a, b) => a.sort_order - b.sort_order)
                                                ?.map((rule) => (
                                                    <div className="flex justify-start items-center space-x-4">
                                                        <div>
                                                            {rule.option_name} - {rule.sort_order}
                                                        </div>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, 0, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {isOpen === bot_flow.id && (
                        <div className="text-center">
                            <button className="bg-primary text-white px-4 py-2 rounded-full mb-8" onClick={(e) => open_popup(e, bot_flow.id, 0, "add")}>
                                Add Flow Inside {bot_flow.name}
                            </button>
                        </div>
                    )}

                    {isOpen === bot_flow.id && (
                        <div className="px-8">
                            {bot_flow.children.map((child) => (
                                <div className="mb-4 bg-gray-100 px-4 py-2">
                                    <div className="flex justify-between">
                                        <div title={child.id}>
                                            <strong>Name:</strong> {child.name} ({child.status})
                                        </div>

                                        <div className="flex space-x-2 items-center">
                                            <div>{child.sort_order}</div>

                                            <button onClick={(e) => open_popup(e, bot_flow.id, child.id, "edit")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                            </button>

                                            <button onClick={(e) => handleDelete(e, child.id)}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div>
                                            <strong>Match Message:</strong> {child.match_message === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">{child.incoming_message}</div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Match Location:</strong> {child.match_location === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            {child.current_location}
                                            {child.current_tag_id > 0 && " - (Tag = " + tags.filter((tag) => tag.id === child.current_tag_id)[0]?.name + ")"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Move Chat:</strong> {child.do_move_chat === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            {child.move_to_location}
                                            {child.move_to_tag > 0 && " - (Tag = " + tags.filter((tag) => tag.id === child.move_to_tag)[0]?.name + ")"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Do Action:</strong> {child.do_action === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            {child.action_to_do == 1 && (
                                                <>
                                                    <strong>Action to do:</strong> Create Booking - {child.booking_year && years.find((y) => y.value == child.booking_year)?.label}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Close:</strong> {child.do_close === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>Archive:</strong> {child.do_archive === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4">
                                        <div>
                                            <strong>End Flow:</strong> {child.end_bot_flow === 1 ? "Yes" : "No"}
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div>
                                            <strong>Send Message:</strong> {child.do_send_message === 1 ? "Yes" : "No"}
                                        </div>
                                        <div className="col-span-3">
                                            <pre>{child.do_send_message === 1 && child.message_to_send}</pre>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div className="flex justify-start items-start">
                                            <div className="mr-4">
                                                <strong>Rules:</strong> {child.bot_flow_rules?.length > 0 ? "Yes" : "No"}
                                            </div>

                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, 0, "add")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                            </button>
                                        </div>

                                        {child.bot_flow_rules?.length > 0 && (
                                            <div className="col-span-3">
                                                {child.bot_flow_rules?.map((rule) => (
                                                    <div>
                                                        <div className="space-x-2">
                                                            <strong>Incoming Message:</strong> {rule.incoming_message} ({rule.status})
                                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, rule.id, "edit")}>
                                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                            </button>
                                                            <button onClick={(e) => edit_rules(e, bot_flow.id, child.id, rule.id, "delete")}>
                                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                            </button>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div>
                                                                <strong>Send Message:</strong> {rule.do_send_message === 1 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-3">{rule.do_send_message === 1 && rule.message_to_send}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div>
                                                                <strong>Move Location:</strong> {rule.do_move_chat === 1 ? "Yes" : "No"}
                                                            </div>
                                                            <div className="col-span-3">{rule.do_move_chat === 1 && rule.move_to_location}</div>
                                                        </div>
                                                        <div className="grid grid-cols-4">
                                                            <div>
                                                                <strong>End Flow:</strong> {rule.end_bot_flow === 1 ? "Yes" : "No"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mt-4 grid grid-cols-4">
                                        <div className="flex justify-start items-start">
                                            <div className="mr-4">
                                                <strong>Button Options:</strong> {child.bot_flow_options?.length > 0 ? "Yes" : "No"}
                                            </div>
                                            <button onClick={(e) => edit_options(e, bot_flow.id, child.id, 0, "add")}>
                                                <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPlusCircle} />
                                            </button>
                                        </div>

                                        <div>
                                            {child.bot_flow_options
                                                ?.sort((a, b) => a.sort_order - b.sort_order)
                                                ?.map((rule) => (
                                                    <div className="flex justify-start items-center space-x-4">
                                                        <div>
                                                            {rule.option_name} - {rule.sort_order}
                                                        </div>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, child.id, rule.id, "edit")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faPenCircle} />
                                                        </button>
                                                        <button onClick={(e) => edit_options(e, bot_flow.id, child.id, rule.id, "delete")}>
                                                            <FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true" icon={faTrashCircle} />
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}

            <div className="h-1 mb-20"></div>

            <Transition.Root show={openPopup === 1} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            const formData = new FormData();
                                            for (const key in values) {
                                                if (values.hasOwnProperty(key)) {
                                                    formData.append(key, values[key]);
                                                }
                                            }

                                            // Append file if it exists
                                            if (values.file) {
                                                formData.append("file", values.file);
                                            }

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow`,
                                                data: formData,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-4 gap-4">
                                                            <div>
                                                                <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Status"
                                                                    name="status"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.status}
                                                                    touched={touched.status}
                                                                    error={errors.status}
                                                                    options={[
                                                                        { value: "Active", label: "Active" },
                                                                        { value: "Disabled", label: "Disabled" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Bot Type"
                                                                    name="bot_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.bot_type}
                                                                    touched={touched.bot_type}
                                                                    error={errors.bot_type}
                                                                    options={[
                                                                        { value: "Whatsapp", label: "Whatsapp" },
                                                                        { value: "Email", label: "Email" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Action"
                                                                    name="action"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.action}
                                                                    touched={touched.action}
                                                                    error={errors.action}
                                                                    options={[
                                                                        { value: "All", label: "All" },
                                                                        { value: "First Incoming Email", label: "First Incoming Email" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-4 gap-4">
                                                            <div>
                                                                <Field name="sort_order" type="text" as={SettingsTextInput} label="Sort Order" />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Close Chat"
                                                                    name="do_close"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_close}
                                                                    touched={touched.do_close}
                                                                    error={errors.do_close}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Archive Chat"
                                                                    name="do_archive"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_archive}
                                                                    touched={touched.do_archive}
                                                                    error={errors.do_archive}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="End Bot Flow"
                                                                    name="end_bot_flow"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.end_bot_flow}
                                                                    touched={touched.end_bot_flow}
                                                                    error={errors.end_bot_flow}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Incoming Message"
                                                                    name="match_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_message}
                                                                    touched={touched.match_message}
                                                                    error={errors.match_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.match_message == 1 && (
                                                                <div>
                                                                    <Field name="incoming_message" type="text" as={SettingsTextInput} label="Incoming Message" />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Current Location"
                                                                    name="match_location"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_location}
                                                                    touched={touched.match_location}
                                                                    error={errors.match_location}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.match_location == 1 && (
                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Current Location"
                                                                            name="current_location"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.current_location}
                                                                            touched={touched.current_location}
                                                                            error={errors.current_location}
                                                                            options={locations}
                                                                        />
                                                                    </div>

                                                                    {(values.current_location === "Residents" ||
                                                                        values.current_location === "Historical" ||
                                                                        values.current_location === "Management 2023") && (
                                                                        <div>
                                                                            <label for="current_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Current Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="current_tag_id"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                {values.current_location === "Residents" && <option value="0">No Tag</option>}

                                                                                {tags
                                                                                    .filter(
                                                                                        (tag) =>
                                                                                            tag.parent_folder === values.current_location ||
                                                                                            (tag.parent_folder === "Historical" && values.current_location == "Management 2023")
                                                                                    )
                                                                                    .map((tag) => (
                                                                                        <option
                                                                                            key={tag.id}
                                                                                            value={tag.id}
                                                                                            className={classNames(tag.id === values.current_tag_id ? "text-white bg-primary" : "text-black")}
                                                                                        >
                                                                                            {tag.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Move Chat"
                                                                    name="do_move_chat"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_move_chat}
                                                                    touched={touched.do_move_chat}
                                                                    error={errors.do_move_chat}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_move_chat == 1 && (
                                                                // <div>
                                                                //     <Field name="move_to_location" type="text" as={SettingsTextInput} label="Location to Move To" />
                                                                // </div>

                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Location to Move To"
                                                                            name="move_to_location"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.move_to_location}
                                                                            touched={touched.move_to_location}
                                                                            error={errors.move_to_location}
                                                                            options={locations}
                                                                        />
                                                                    </div>

                                                                    {(values.move_to_location === "Residents" ||
                                                                        values.move_to_location === "Historical" ||
                                                                        values.move_to_location === "Management 2023") && (
                                                                        <div>
                                                                            <label for="move_to_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Current Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="move_to_tag"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                {values.move_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                {tags
                                                                                    .filter(
                                                                                        (tag) =>
                                                                                            tag.parent_folder === values.move_to_location ||
                                                                                            (tag.parent_folder === "Historical" && values.move_to_location == "Management 2023")
                                                                                    )
                                                                                    .map((tag) => (
                                                                                        <option
                                                                                            key={tag.id}
                                                                                            value={tag.id}
                                                                                            className={classNames(tag.id === values.move_to_tag ? "text-white bg-primary" : "text-black")}
                                                                                        >
                                                                                            {tag.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Update Data"
                                                                    name="do_update_data"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_update_data}
                                                                    touched={touched.do_update_data}
                                                                    error={errors.do_update_data}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_update_data == 1 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Field to Update"
                                                                        name="field_to_update"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.field_to_update}
                                                                        touched={touched.field_to_update}
                                                                        error={errors.field_to_update}
                                                                        options={[
                                                                            { value: "", label: "None" },
                                                                            { value: "bookings.current_funding_type", label: "Bookings -> Student Type" },
                                                                            { value: "bookings.budget_id", label: "Bookings -> Budget" },
                                                                            { value: "bookings.property_id", label: "Bookings -> Property" },
                                                                            { value: "bookings.unit_type", label: "Bookings -> Unit Type" },
                                                                            { value: "custom.move_in_date", label: "Custom -> Move In Date" },
                                                                            { value: "user_profile.gender", label: "User Profile -> Gender" },
                                                                            { value: "user_profile.id_number", label: "User Profile -> ID Number" },
                                                                            { value: "user_profile.institution_campus", label: "User Profile -> Campus" },
                                                                            { value: "user_profile.institution_study", label: "User Profile -> Institution" },
                                                                            { value: "user_profile.study_year", label: "User Profile -> Study Year" },
                                                                            { value: "users.name", label: "Users -> Name" },
                                                                            { value: "users.surname", label: "Users -> Surname" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="grid grid-cols-3 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Do Action"
                                                                    name="do_action"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_action}
                                                                    touched={touched.do_action}
                                                                    error={errors.do_action}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_action == 1 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Action to do"
                                                                        name="action_to_do"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.action_to_do}
                                                                        touched={touched.action_to_do}
                                                                        error={errors.action_to_do}
                                                                        options={[
                                                                            { value: 1, label: "Create a Booking (Old Booking Active)" },
                                                                            { value: 2, label: "Create a Booking and Archive old Booking" },
                                                                            { value: 3, label: "Refund - Location Move" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}

                                                            {values.do_action == 1 && (values.action_to_do == 1 || values.action_to_do == 2) && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Year"
                                                                        name="booking_year"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.booking_year}
                                                                        touched={touched.booking_year}
                                                                        error={errors.booking_year}
                                                                        options={years}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>

                                                        {values.do_action == 1 && (values.action_to_do == 1 || values.action_to_do == 2) && (
                                                            <div className="grid grid-cols-3 gap-4">
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Move New Booking"
                                                                        name="do_move_booking"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.do_move_booking}
                                                                        touched={touched.do_move_booking}
                                                                        error={errors.do_move_booking}
                                                                        options={[
                                                                            { value: 0, label: "No" },
                                                                            { value: 1, label: "Yes" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.do_move_booking == 1 && (
                                                                    <div className="col-span-2 grid grid-cols-2">
                                                                        <div>
                                                                            <SettingsSelect
                                                                                label="Location to Move To"
                                                                                name="move_booking_to_location"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.move_booking_to_location}
                                                                                touched={touched.move_booking_to_location}
                                                                                error={errors.move_booking_to_location}
                                                                                options={locations}
                                                                            />
                                                                        </div>

                                                                        {(values.move_booking_to_location === "Residents" ||
                                                                            values.move_booking_to_location === "Historical" ||
                                                                            values.move_booking_to_location === "Management 2023") && (
                                                                            <div>
                                                                                <label for="move_booking_to_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                    Move to Tag
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="move_booking_to_tag"
                                                                                    className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                >
                                                                                    {values.move_booking_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                    {tags
                                                                                        .filter(
                                                                                            (tag) =>
                                                                                                tag.parent_folder === values.move_booking_to_location ||
                                                                                                (tag.parent_folder === "Historical" && values.move_booking_to_location == "Management 2023")
                                                                                        )
                                                                                        .map((tag) => (
                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}
                                                                                                className={classNames(tag.id === values.move_booking_to_tag ? "text-white bg-primary" : "text-black")}
                                                                                            >
                                                                                                {tag.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </Field>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div>
                                                            <SettingsSelect
                                                                label="Send Message"
                                                                name="do_send_message"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.do_send_message}
                                                                touched={touched.do_send_message}
                                                                error={errors.do_send_message}
                                                                options={[
                                                                    { value: 0, label: "No" },
                                                                    { value: 1, label: "Yes" },
                                                                ]}
                                                            />
                                                        </div>

                                                        {values.do_send_message == 1 && (
                                                            <div>
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Robot Colour"
                                                                        name="robot_colour"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.robot_colour}
                                                                        touched={touched.robot_colour}
                                                                        error={errors.robot_colour}
                                                                        options={[
                                                                            { value: 0, label: "Red" },
                                                                            { value: 1, label: "Green" },
                                                                        ]}
                                                                    />
                                                                </div>

                                                                {values.bot_type == "Email" ? (
                                                                    <div>
                                                                        <Field name="email_subject" type="text" as={SettingsTextInput} label="Email Subject" />
                                                                        <div className="mt-4">
                                                                            <SettingsTextArea
                                                                                name="message_to_send"
                                                                                placeholder="Message - Email"
                                                                                errors={errors.message_to_send}
                                                                                emojis={true}
                                                                                setFieldValue={setFieldValue}
                                                                                handleChange={handleChange}
                                                                                value={values.message_to_send}
                                                                                wysiwyg={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <div className="mt-4">
                                                                            <label htmlFor="message_to_send" className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                                Message
                                                                            </label>
                                                                            <BookingTextArea
                                                                                as="textarea"
                                                                                rows={5}
                                                                                name="message_to_send"
                                                                                id="message_to_send"
                                                                                placeholder="Message to Send"
                                                                                errors={errors}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {values.id > 0 ? (
                                                                    <div className="mt-4">
                                                                        <label htmlFor="message_to_send" className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                            Upload a File (Does not work for List Message Type)
                                                                        </label>
                                                                        {values.file_path !== null ? (
                                                                            <p>
                                                                                File Uploaded <img src={process.env.REACT_APP_LARAVEL_URL + "/" + values.file_path} alt="File Uploaded" />
                                                                            </p>
                                                                        ) : (
                                                                            <input
                                                                                id="file"
                                                                                name="file"
                                                                                type="file"
                                                                                onChange={(event) => {
                                                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-4">File upload only works after saving</div>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Message Type"
                                                                    name="message_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.message_type}
                                                                    touched={touched.message_type}
                                                                    error={errors.message_type}
                                                                    options={[
                                                                        { value: 0, label: "Normal Message" },
                                                                        { value: 1, label: "List (10 Items Max)" },
                                                                        { value: 2, label: "Buttons (3 Items Max)" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.message_type > 0 && (
                                                                <div>
                                                                    <SettingsSelect
                                                                        label="Items"
                                                                        name="message_class"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.message_class}
                                                                        touched={touched.message_class}
                                                                        error={errors.message_class}
                                                                        options={[
                                                                            { value: "", label: "None" },
                                                                            { value: "funding_source_buttons", label: "Funding Source" },
                                                                            { value: "campus_buttons", label: "Campus Buttons" },
                                                                            { value: "study_year_buttons", label: "Study Years" },
                                                                            { value: "institution_buttons", label: "Institutions" },
                                                                            { value: "applying_as_buttons", label: "Student, Parent, Sponsor Buttons" },
                                                                            { value: "continue_button", label: "Continue Button" },
                                                                            { value: "budget_buttons", label: "Budget Buttons" },
                                                                            { value: "property_buttons", label: "Property Buttons" },
                                                                            { value: "unit_type_buttons", label: "Unit Type Buttons" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openPopup === 2} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow_rule`,
                                                data: values,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Message"
                                                                    name="match_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_message}
                                                                    touched={touched.match_message}
                                                                    error={errors.match_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div>{values.match_message == 1 && <Field name="incoming_message" type="text" as={SettingsTextInput} label="Incoming Message" />}</div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Location"
                                                                    name="match_location"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.match_location}
                                                                    touched={touched.match_location}
                                                                    error={errors.match_location}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.match_location == 1 && (
                                                                    <div className="grid grid-cols-2">
                                                                        <div>
                                                                            <SettingsSelect
                                                                                label="Current Location"
                                                                                name="current_location"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.current_location}
                                                                                touched={touched.current_location}
                                                                                error={errors.current_location}
                                                                                options={locations}
                                                                            />
                                                                        </div>

                                                                        {(values.current_location === "Residents" ||
                                                                            values.current_location === "Historical" ||
                                                                            values.current_location === "Management 2023") && (
                                                                            <div>
                                                                                <label for="current_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                    Current Tag
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="current_tag_id"
                                                                                    className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                >
                                                                                    {values.current_location === "Residents" && <option value="0">No Tag</option>}

                                                                                    {tags
                                                                                        .filter(
                                                                                            (tag) =>
                                                                                                tag.parent_folder === values.current_location ||
                                                                                                (tag.parent_folder === "Historical" && values.current_location == "Management 2023")
                                                                                        )
                                                                                        .map((tag) => (
                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}
                                                                                                className={classNames(tag.id === values.current_tag_id ? "text-white bg-primary" : "text-black")}
                                                                                            >
                                                                                                {tag.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </Field>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Match Funding Type"
                                                                    name="do_match_funding_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_match_funding_type}
                                                                    touched={touched.do_match_funding_type}
                                                                    error={errors.do_match_funding_type}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                {values.do_match_funding_type == 1 && (
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Funding Type"
                                                                            name="current_funding_type"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.current_funding_type}
                                                                            touched={touched.current_funding_type}
                                                                            error={errors.current_funding_type}
                                                                            options={[
                                                                                { label: "Choose an option", value: 0 },
                                                                                { label: "NSFAS UJ", value: 4 },
                                                                                { label: "NSFAS WITS", value: 5 },
                                                                                { label: "CJC", value: 6 },
                                                                                { label: "NSFAS TVET", value: 1 },
                                                                                { label: "Bursary", value: 2 },
                                                                                { label: "Private", value: 3 },
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Status"
                                                                    name="status"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.status}
                                                                    touched={touched.status}
                                                                    error={errors.status}
                                                                    options={[
                                                                        { value: "Active", label: "Active" },
                                                                        { value: "Disabled", label: "Disabled" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div>
                                                                <SettingsSelect
                                                                    label="End Bot Flow"
                                                                    name="end_bot_flow"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.end_bot_flow}
                                                                    touched={touched.end_bot_flow}
                                                                    error={errors.end_bot_flow}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            <div className="col-span-2">
                                                                <SettingsSelect
                                                                    label="Send Message"
                                                                    name="do_send_message"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_send_message}
                                                                    touched={touched.do_send_message}
                                                                    error={errors.do_send_message}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_send_message == 1 && (
                                                                <div className="col-span-2">
                                                                    <BookingTextArea as="textarea" rows={5} name="message_to_send" id="message_to_send" placeholder="Message to Send" errors={errors} />
                                                                </div>
                                                            )}

                                                            <div>
                                                                <SettingsSelect
                                                                    label="Move Chat"
                                                                    name="do_move_chat"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.do_move_chat}
                                                                    touched={touched.do_move_chat}
                                                                    error={errors.do_move_chat}
                                                                    options={[
                                                                        { value: 0, label: "No" },
                                                                        { value: 1, label: "Yes" },
                                                                    ]}
                                                                />
                                                            </div>

                                                            {values.do_move_chat == 1 && (
                                                                // <div>
                                                                //     <Field name="move_to_location" type="text" as={SettingsTextInput} label="Location to Move To" />
                                                                // </div>

                                                                <div className="grid grid-cols-2">
                                                                    <div>
                                                                        <SettingsSelect
                                                                            label="Location to Move To"
                                                                            name="move_to_location"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.move_to_location}
                                                                            touched={touched.move_to_location}
                                                                            error={errors.move_to_location}
                                                                            options={locations}
                                                                        />
                                                                    </div>

                                                                    {(values.move_to_location === "Residents" ||
                                                                        values.move_to_location === "Historical" ||
                                                                        values.move_to_location === "Management 2023") && (
                                                                        <div>
                                                                            <label for="move_to_location" class="text-sm font-medium mb-2 text-gray-700 flex items-baseline mx-2">
                                                                                Current Tag
                                                                            </label>
                                                                            <Field
                                                                                as="select"
                                                                                name="move_to_tag"
                                                                                className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                            >
                                                                                {values.move_to_location === "Residents" && <option value="0">No Tag</option>}

                                                                                {tags
                                                                                    .filter(
                                                                                        (tag) =>
                                                                                            tag.parent_folder === values.move_to_location ||
                                                                                            (tag.parent_folder === "Historical" && values.move_to_location == "Management 2023")
                                                                                    )
                                                                                    .map((tag) => (
                                                                                        <option
                                                                                            key={tag.id}
                                                                                            value={tag.id}
                                                                                            className={classNames(tag.id === values.move_to_tag ? "text-white bg-primary" : "text-black")}
                                                                                        >
                                                                                            {tag.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </Field>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openPopup === 3} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}save_bot_flow_option`,
                                                data: values,
                                            })
                                                .then((resp) => {
                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
                                                    })
                                                        .then((response) => {
                                                            setBotFlows(response.data[0]);
                                                            setYears(response.data[1]);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            alert("Failed to get bot flows");
                                                            setLoading(false);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    console.log(error.response);
                                                })
                                                .finally(() => {
                                                    setSubmitting(false);
                                                    setOpenPopup(false);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                    <div className="space-y-4">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div>
                                                                <Field name="option_name" type="text" as={SettingsTextInput} label="Option Name" />
                                                            </div>

                                                            <div>
                                                                <Field name="sort_order" type="text" as={SettingsTextInput} label="Sort Order" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            icon={faSave}
                                                            text="Save"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />

                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                            onClick={() => setOpenPopup(false)}
                                                            ref={cancelButtonRef}
                                                        >
                                                            CLOSE
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
