import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AppLoader from "../../components/Helpers/AppLoader";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import ProfilePictureUpload from "../../components/Forms/ProfilePictureUpload";
import ProfilePicture from "../../components/Auth/ProfilePicture";
import SubmitButton from "../../components/Forms/SubmitButton";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { getStaff } from "../../store/auth/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuickFormSelect from "../../components/Forms/QuickFormSelect";
import PhoneInput from "react-phone-number-input";
import TypeIcon from "../../components/Helpers/TypeIcon";
import TimeSince from "../../components/Helpers/TimeSince";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ManageUsers() {
    const { user_id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [profileLoading, setProfileLoading] = useState(false);
    const [allowClick, setAllowClick] = useState(true);
    const [allMessages, setAllMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const { properties } = useSelector((state) => state.properties);
    const { settings } = useSelector((state) => state.settings);
    const [propertiesOption, setPropertiesOption] = useState([]);

    const { current_staff, staff } = useSelector((state) => state.auth);

    useEffect(async () => {
        if (!users || users.length === 0) {
            setLoading(true);

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}channels`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}tagsList`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}collectionList`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
            });

            if (settings && settings.brokers === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}brokers`,
                });
            }

            if (settings && settings.renewals === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                });
            }

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}staff`,
            })
                .then((response) => {
                    setUsers(response.data);

                    if (user_id > 0) {
                        response.data.forEach((usr) => {
                            if (usr.id == user_id) {
                                if (usr.cell !== null) {
                                    usr.cell = "+" + usr.cell;
                                }
                                setSelectedUser(usr);
                            }
                        });
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    alert("error getting users");
                });

            if (user_id > 0) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}staff_messages/${user_id}`,
                })
                    .then((response) => {
                        setAllMessages(response.data);
                        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

                        setLoading(false);
                    })
                    .catch((error) => {
                        alert("error getting users");
                    });
            }
        }

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }

        let propList = [];
        properties.forEach(function (property) {
            if (property.value != "3" && property.value != "7") {
                if (property.value == "6") {
                    property.label = property.label.split(" - ")[0];
                    propList.push(property);
                } else {
                    propList.push(property);
                }
            }
        });
        setPropertiesOption(propList);
    }, [users]);

    async function deleteProfilePicture(user_id) {
        setProfileLoading(true);
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}profile_picture/${user_id}`,
        }).then(() => {
            window.location.reload();
        });
    }

    // console.log(selectedUser);

    if (loading === true) return <AppLoader />;

    return (
        <>
            <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
                <div className="rounded-3xl bg-light_background w-full xl:w-72 xl:relative flex-none">
                    <div className="h-14 flex rounded-t-3xl items-center justify-between px-4 bg-dark_background z-20 xl:w-72 relative xl:fixed">
                        <h3 className="text-lg leading-6 font-light text-white capitalize">User List</h3>

                        <button
                            type="button"
                            className="text-white uppercase px-2 py-1 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                            onClick={() => setSelectedUser({ id: 0, name: "", surname: "", emnail: "", profile_picture: "" })}
                        >
                            <FontAwesomeIcon icon={regular("plus")} className="h-5 w-5 text-white" />
                        </button>
                    </div>

                    <div className="h-auto sm:max-h-44 md:max-h-80 xl:max-h-screen overflow-y-scroll xl:overflow-auto xl:-bottom-14 z-10 pt-14 -mt-14 xl:mt-0">
                        {users.length ? (
                            users.map((user) => (
                                <div key={user.id}>
                                    <div
                                        className={classNames(
                                            // booking.id === booking_id ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background",
                                            "border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover transition ease-linear delay-10"
                                        )}
                                    >
                                        <a href={"/settings_users/" + user.id}>
                                            <div className="flex space-x-3 items-center relative">
                                                <div>
                                                    <ProfilePicture id={user.id} />
                                                </div>

                                                <div className="flex-1 space-y-2">
                                                    <div className="flex items-center justify-between flex-wrap">
                                                        <h3>
                                                            {user.name} {user.surname}
                                                            <br />
                                                            {user.email}
                                                            <br />
                                                            {user.active_account == 1 ? "Active" : "Inactive"}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="border-t-2" />
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1">
                    {selectedUser !== false ? (
                        <>
                            <Formik
                                initialValues={selectedUser}
                                enableReinitialize="true"
                                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                    if (values.cell !== null && values.cell !== undefined && values.building_manager == 1) {
                                        values.cell = values.cell.replace("+", "");
                                    } else {
                                        values.cell = "";
                                    }
                                    await axios({
                                        method: "post",
                                        url: `${process.env.REACT_APP_API_URL}update_user_profile`,
                                        data: values,
                                    })
                                        .then((response) => {
                                            // console.log(response.data);

                                            if (response.data === "done") {
                                                toast(<NotificationToast title={"Profile Updates"} message="User Profile has been updated" />);
                                                dispatch(getStaff());
                                                // setUsers([]);
                                            } else if (response.data > 0) {
                                                window.location = "/settings_users/" + response.data;
                                            } else {
                                                toast(<NotificationToast title={"Error"} message={response.data} type="error" />);
                                            }

                                            if (values.cell !== "" && values.cell !== undefined && values.building_manager == 1) {
                                                values.cell = "+" + values.cell;
                                            } else {
                                                // values.cell = "+27";
                                            }
                                        })
                                        .catch((response) => {
                                            // console.log(response.data);
                                            toast(<NotificationToast title={"Something went wrong"} message={"Server Error"} type="error" />);
                                        });
                                }}
                            >
                                {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                    <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                        <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                            User ID: {selectedUser.id}
                                            <div className="grid grid-cols-6 gap-6 mt-6">
                                                <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                <Field name="surname" type="text" as={SettingsTextInput} label="Surname" />
                                                <Field name="email" type="email" as={SettingsTextInput} label="Email" />
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    as={SettingsTextInput}
                                                    label="Password"
                                                    tooltip="Only enter something here if you want to change the current password"
                                                />

                                                <Field name="messagebird_number" type="text" as={SettingsTextInput} label="Messagebird Number" disabled={current_staff.isSuper == 1 ? false : true} />
                                                <Field
                                                    name="messagebird_namespace"
                                                    type="text"
                                                    as={SettingsTextInput}
                                                    label="Messagebird Namespace"
                                                    disabled={current_staff.isSuper == 1 ? false : true}
                                                />
                                                <Field
                                                    name="messagebird_channel_id"
                                                    type="text"
                                                    as={SettingsTextInput}
                                                    label="Messagebird Channel ID"
                                                    disabled={current_staff.isSuper == 1 ? false : true}
                                                />
                                            </div>
                                            <div className="grid md:grid-cols-4 gap-6 mt-6">
                                                <QuickFormSelect
                                                    label="Building Manager"
                                                    name="building_manager"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.building_manager}
                                                    touched={touched.building_manager}
                                                    error={errors.building_manager}
                                                    options={"yesNo"}
                                                />
                                                <QuickFormSelect
                                                    label="Restrict Views"
                                                    name="restrict_views"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.restrict_views}
                                                    touched={touched.restrict_views}
                                                    error={errors.restrict_views}
                                                    options={"yesNo"}
                                                />
                                                <QuickFormSelect
                                                    label="Active Account"
                                                    name="active_account"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.active_account}
                                                    touched={touched.active_account}
                                                    error={errors.active_account}
                                                    options={"yesNo"}
                                                />
                                                <QuickFormSelect
                                                    label="Property"
                                                    name="bm_property_id"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.bm_property_id}
                                                    touched={touched.bm_property_id}
                                                    error={errors.bm_property_id}
                                                    options={propertiesOption}
                                                />
                                                <QuickFormSelect
                                                    label="Create Orders"
                                                    name="create_order"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.create_order}
                                                    touched={touched.create_order}
                                                    error={errors.create_order}
                                                    options={"yesNo"}
                                                />
                                                <QuickFormSelect
                                                    label="Super User"
                                                    name="isSuper"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.isSuper}
                                                    touched={touched.isSuper}
                                                    error={errors.isSuper}
                                                    options={"yesNo"}
                                                    disabled={current_staff.isSuper == 1 ? false : true}
                                                />
                                                <QuickFormSelect
                                                    label="Edit Applicant Fields"
                                                    name="canEditApplicantFields"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.canEditApplicantFields}
                                                    touched={touched.canEditApplicantFields}
                                                    error={errors.canEditApplicantFields}
                                                    options={"yesNo"}
                                                    disabled={current_staff.isSuper == 1 ? false : true}
                                                />
                                                <QuickFormSelect
                                                    label="Hide Leasing"
                                                    name="hideLeasing"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.hideLeasing}
                                                    touched={touched.hideLeasing}
                                                    error={errors.hideLeasing}
                                                    options={"yesNo"}
                                                    disabled={current_staff.isSuper == 1 ? false : true}
                                                />

                                                {values.building_manager == 1 ? (
                                                    <div>
                                                        <label className="text-sm text-dark_background">Cell Number</label>
                                                        <br />
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("cell", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="cell"
                                                            value={values.cell}
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="text-sm font-medium mb-2 text-gray-700 mt-4">
                                                <p>Profile Picture</p>
                                                <div className="flex items-center space-x-4 mt-4">
                                                    <div className="flex items-center">
                                                        <ProfilePicture id={selectedUser?.id} />
                                                        {selectedUser?.profile_picture && (
                                                            <FontAwesomeIcon
                                                                className={`${profileLoading ? "animate-spin" : "cursor-pointer"} "h-6 w-6 text-red-400 cursor-pointer"`}
                                                                icon={profileLoading ? regular("loader") : regular("times")}
                                                                onClick={() => {
                                                                    deleteProfilePicture(selectedUser?.id);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <ProfilePictureUpload selectedUser={selectedUser} />
                                                </div>
                                            </div>
                                            <div className="mt-8">
                                                <SubmitButton
                                                    name="Save"
                                                    disabled={!isValid || isSubmitting || !dirty}
                                                    isSubmitting={isSubmitting}
                                                    icon={regular("save")}
                                                    text="Save"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                            {/* {allMessages.length > 0 ?
                            <>
                                <br/>
                                <p>Messages: <small>Click on the message to open the chat</small></p>
                                

                                <div className="w-full overflow-y-scroll scroll-smooth max-h-60 border-2">

                                    {allMessages.length ? (
                                        allMessages &&
                                        allMessages.map((d) => {

                                            return (

                                                <Link to={"/booking/unassigned?user_id="+d.user_id}> 
                                                    <div key={d.id} className="justify-end ml-24 flex py-4">
                                                        <div className="flex-row-reverse">
                                                            <div className="bg-blue-200 p-4 rounded-md text-sm max-w-sm break-words">
                                                                <div
                                                                    className="dangerousPFormat"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            d.message_html && d.message_html !== ""
                                                                                ? new DOMParser()
                                                                                        .parseFromString(d.message_html, "text/html")
                                                                                        .body.innerHTML.replace(/<\/?a[^>]*>/g, "")
                                                                                        .replace(/<blockquote\b[^>]*>(.*?)<\/blockquote>/g, "")
                                                                                : d.message,
                                                                    }}
                                                                /> 
                                                                {d.failed_reason !== undefined && d.failed_reason !== null ? <><hr/><p className="text-xs italic mt-2">{d.failed_reason}</p></> : ""}
                                                            </div>
                                                            <div
                                                                    className={classNames(
                                                                        staff.find((e) => e.id === parseInt(d.from)) || d.from == -1 ? "justify-end" : "justify-start",
                                                                        "flex items-center text-gray-900 text-xs"
                                                                    )}
                                                                >
                                                                    <TypeIcon platform={d.platform} className="w-4 h-4 mr-2" />

                                                                    <TimeSince time={d.created_at} className="mr-2" />
                                                                </div>
                                                            </div>
                                                            <div className="text-left flex-shrink-0">
                                                                <ProfilePicture id={parseInt(d.from)} />
                                                        </div>
                                                    </div>
                                                </Link>

                                            )
                                            
                                        })
                                    ):""}
                                    <div ref={messagesEndRef} />
                                </div>
                            </>
                        : ""} */}
                        </>
                    ) : (
                        <p>Choose a user</p>
                    )}
                </div>
            </div>
        </>
    );
}
